import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Section from "../components/Section";
import GoogleMap from "../components/GoogleMap";
import stockperksImg from "../images/stockperks.png";
import { H1 } from "../components/TextHeadings";
import Paragraph from "../components/Paragraph";
import colors from "../utils/colors";
import SubSection from "../components/SubSection";
import { isMobile } from "react-device-detect";

const ContactPage = () => (
  <Layout>
    <SEO
      title="Contact with Stockperks"
      url={"/contact/"}
      description={
        "We’re located in Midtown Manhattan, get in touch with us now! hello@stockperks.com"
      }
      image={stockperksImg}
    />
    <Section isTextCentered={false}>
      <SubSection style={{flexDirection: isMobile ? "column" : "row"}}>
        <div style={styles.ContactInfo}>
          <H1>Contact</H1>
          <Paragraph>
            We’re located in Midtown Manhattan, <br /> get in touch with us now!
          </Paragraph>
          <Paragraph style={styles.ContactBold}>
            500 7th Avenue, 8th Floor
            <br />
            New York, NY 10018
          </Paragraph>
          <Paragraph>
            Connect with us to learn more. <br /> We’d love to hear from you!
          </Paragraph>
          <Paragraph>
            <a href="mailto:hello@stockperks.com" style={styles.ContactBold}>hello@stockperks.com</a>
          </Paragraph>
        </div>
        <GoogleMap
          src="https://maps.google.com/maps?q=500%207th%20Avenue&t=&z=17&ie=UTF8&iwloc=&output=embed"
          title="Stockperks HQ on Google Maps"
        />
      </SubSection>
    </Section>
  </Layout>
);

const styles = {
  ContactInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent:"flex-start",
    alignItems: "flex-start",
    paddingRight: 25
  },
  ContactBold: {
    color: colors.black,
    fontWeight: "bold"
  }
}

export default ContactPage;
