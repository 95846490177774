import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import colors from "../utils/colors";
import { windowSize } from "../utils/responsive";

const GoogleMap = ({ src, title }) => (
  <GoogleMapContainer>
    <IFrame src={src} title={title} />
  </GoogleMapContainer>
);

GoogleMap.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default GoogleMap;

const GoogleMapContainer = styled.div`
  background: ${colors.lightGray};
  border: 1px solid ${colors.lightGray};
  display: flex;
  margin-top: 20px;
  width: 540px;
  height: 460px;
  overflow: hidden;
  @media (max-width: ${windowSize.medium}px) {
    width: 300px;
    height: 290px;  
  }
`;

const IFrame = styled.iframe`
  border: 0;
  height: 100%;
  width: 100%;
`;
